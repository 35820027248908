export function formatTime(datetime) {
	if (!datetime) return "";
	const timeStr = moment(frappe.datetime.convert_to_user_tz(datetime)).format(
		"DD-MM-YYYY HH:mm:ss"
	);
	return timeStr;
}

export const STATUS_COLOR = {
	Planned: "gray",
	Completed: "green",
	Failed: "red",
	"Partially Complete": "yellow",
};
