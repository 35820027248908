frappe.provide("mesrajaya.util");

export const MONTHS = [
	"JAN",
	"FEB",
	"MAR",
	"APR",
	"MAY",
	"JUN",
	"JUL",
	"AUG",
	"SEP",
	"OCT",
	"NOV",
	"DEC",
];

/**
 * Get the month in number format
 * @param {string} month
 * @returns {int}
 */
export function get_month_number(month) {
	return MONTHS.indexOf(month.toUpperCase()) + 1;
}

/**
 * Get the month in short string format
 * @param {number} month must be a integer
 * @param {boolean} is_index is true if it is the array index
 * @returns {string}
 */
export function get_month_string(index, is_index = false) {
	index -= !is_index;
	return MONTHS[index];
}

/**
 * Compare both string without matching case
 * @param {string} a
 * @param {string} b
 * @returns {boolean}
 */
export function compare(a, b) {
	return (a || "").toUpperCase() === (b || "").toUpperCase();
}

/**
 * Get address in inline printable format
 * @param {object} address
 * @returns {string}
 */
export function get_inline_address(address) {
	const fields = [
		address.address_line1,
		address.address_line2,
		address.city,
		address.county,
		address.pincode,
		address.state,
		address.country,
	];
	return fields.filter((v) => !!v).join(", ");
}

/**
 * Get datetime in printable format
 * @param {string} datetime
 * @returns {string}
 */
export function format_datetime(datetimeString) {
	const datetime = new Date(datetimeString);

	// Define options for formatting
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		fractionalSecondDigits: 3, // Show milliseconds with 3 digits
		timeZoneName: "short",
	};

	// Format the date and time
	return datetime.toLocaleDateString("en-US", options);
}

Object.assign(mesrajaya.util, {
	MONTHS,
	get_month_number,
	get_month_string,
	compare,
	get_inline_address,
});
