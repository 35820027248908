import { eventBus } from "./eventBus";
import Home from "./Home.vue";

frappe.provide("frappe.mesrajaya");

frappe.mesrajaya.delivery_trip_web = class {
	constructor(main) {
		this.main = main;
		this.make_body();
	}
	make_body() {
		this.$el = this.main.find("#app");
		this.$vue = new Vue({
			el: this.$el[0],
			data() {
				return { doc: null, reasons: [] };
			},
			created() {
				const searchParams = new URLSearchParams(
					window.location.search
				);
				const hash = searchParams.get("trip");
				if (!hash) {
					frappe.throw(
						"Link has expired or broken, please get the latest link from your manager."
					);
				} else {
					this.loadDoc(hash);
				}
				eventBus.$on("load-doc", () => this.loadDoc(hash));
			},
			render(h) {
				return h(Home, {
					props: { doc: this.doc, reasons: this.reasons },
				});
			},
			methods: {
				loadDoc(hash) {
					frappe.freeze("Loading...");
					frappe
						.call({
							method: "mesrajaya.mesrajaya.doctype.planned_delivery_trip.planned_delivery_trip.get_detail",
							args: { hash },
						})
						.then(async (res) => {
							if (res.message.success) {
								this.doc = res.message.doc;
								this.reasons = res.message.reasons;
								frappe.unfreeze();
							} else {
								frappe.unfreeze();
								frappe.throw(res.message.message);
							}
						});
				},
			},
		});
		this.$eventBus = eventBus;
	}
	setup_header() {}
};
