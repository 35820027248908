
	frappe.templates['leave_application_dashboard'] = `
{% if not jQuery.isEmptyObject(data) %}
<table class="table table-bordered">
	<thead>
		<tr>
			<th style="width: 16%">{{ __("Leave Type") }}</th>
			<th style="width: 16%" class="text-right">{{ __("Total Allocated Leave(s)") }}</th>
			<th style="width: 16%" class="text-right">{{ __("Expired Leave(s)") }}</th>
			<th style="width: 16%" class="text-right">{{ __("Used Leave(s)") }}</th>
			<th style="width: 16%" class="text-right">{{ __("Leave(s) Pending Approval") }}</th>
			<th style="width: 16%" class="text-right">{{ __("Available Leave(s)") }}</th>
		</tr>
	</thead>
	<tbody>
		{% for(const [key, value] of Object.entries(data)) { %}
			<tr>
				<td> {%= key %} </td>
				<td class="text-right"> {%= value["total_leaves"] %} </td>
				<td class="text-right"> {%= value["expired_leaves"] %} </td>
				<td class="text-right"> {%= value["leaves_taken"] %} </td>
				<td class="text-right"> {%= value["leaves_pending_approval"] %} </td>
				<td class="text-right"> {%= value["remaining_leaves"] %} </td>
			</tr>
		{% } %}
	</tbody>
</table>
{% else %}
<p style="margin-top: 30px;"> No Leave has been allocated. </p>
{% endif %}
`;
